import * as uuid from 'uuid';

/**
 * This runs on every page load, looking or whether the page is loaded as a result of logging in on
 * Shopify's hosted new customer accounts website and then returning to browsing.
 *
 * This fires every time someone returns from the new customer accounts website, not only when an
 * actual transition from unauthenticated to authenticated took place. There is no trivial way to
 * detect that.
 *
 * @todo refactor to compare to a session storage value?
 */
function onDOMContentLoaded(_event?: Event) {
  const customer = window.auth_observer_customer;
  if (!customer) {
    return;
  }

  if (document.referrer?.includes('account.langehair.com')) {
    type Detail = WindowEventMap['visitor-authenticated']['detail'];

    const event = new CustomEvent<Detail>('visitor-authenticated', {
      detail: {
        customer_token: customer.token,
        default_address: customer.default_address,
        first_name: customer.first_name,
        id: customer.id,
        email: customer.email,
        event_id: uuid.v4(),
        last_name: customer.last_name,
        name: customer.name,
        phone: customer.phone
      }
    });
    dispatchEvent(event);
  }
}

if (document.readyState === 'complete' || document.readyState === 'interactive') {
  setTimeout(onDOMContentLoaded);
} else {
  addEventListener('DOMContentLoaded', onDOMContentLoaded);
}
